<template>
    <div>
        <v-dialog
            v-model="showDialogHOResp"
            transition="dialog-top-transition"
            persistent
            width="600"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-form
                ref="form"
                v-model="validForm"
                lazy-validation
            >
                <v-card>
                    <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                        Homeowner`s Responsibility
                    </v-card-title>

                    <v-card-text style="margin-top: 20px;">
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Landscaping Removal'" 
                                    :subDescription="'This may include, but is not limited to, trees, bushes, stumps, and any vegetation that obstructs excavation'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.landscaping.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.landscaping.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.landscaping.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.landscaping.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'HOA approval'" 
                                    :subDescription="'Submit all required paperwork to your HOA. Once approved, provide us with the approval letter. Is this required?'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.hoaApproval.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.hoaApproval.apply == 1"
                            >
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.hoaApproval.yesNo" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>

                                <div style="width: 100%;" v-if="hoResponsabilityRequest.hoaApproval.yesNo == 1">
                                    <v-combobox
                                        v-model="hoResponsabilityRequest.hoaApproval.listSelected"
                                        :items="listResponsible2"
                                        item-text="responsibleDescription"
                                        item-value="id"
                                        multiple
                                        chips
                                        :rules=[validations.required]
                                        clearable
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-combobox>
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="hoResponsabilityRequest.hoaApproval.note"
                                        rows="2"
                                        row-height="15"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </div>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Capping irrigation'" 
                                    :subDescription="'The sprinkler company must cut down the irrigation system in the future pool area and reroute it once excavation is complete'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.cappingIrrigation.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.cappingIrrigation.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.cappingIrrigation.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.cappingIrrigation.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Plywood'" 
                                    :subDescription="'It is recommended to protect the driveway and/or sidewalk from significant damage. Plywood must be laid before excavation begins'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.plywood.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.plywood.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.plywood.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.plywood.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Fence removal'" 
                                    :subDescription="'Remove fence and gate sections as needed for excavation and machinery access'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.fenceRemoval.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.fenceRemoval.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.fenceRemoval.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.fenceRemoval.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Furniture removal'" 
                                    :subDescription="'Remove any furniture, trampolines, or play areas. These items must be stored in a location that does not affect pool construction and must be removed before excavation begins'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.furnitureRemoval.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.furnitureRemoval.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.furnitureRemoval.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.furnitureRemoval.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Paver Removal'" 
                                    :subDescription="'Remove existing pavers from the back patio area. They must be disposed of or stored in a manner that does not affect pool construction, and this must be done before excavation starts'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.paverRemoval.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>                            
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.paverRemoval.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.paverRemoval.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.paverRemoval.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Concrete Slab Removal'" 
                                    :subDescription="'Remove the existing concrete slab and dispose of the debris'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.concreteSlabRemoval.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <div v-if="hoResponsabilityRequest.concreteSlabRemoval.apply == 1">
                                    <v-combobox
                                        v-model="hoResponsabilityRequest.concreteSlabRemoval.listSelected"
                                        :items="listResponsible"
                                        item-text="responsibleDescription"
                                        item-value="id"
                                        multiple
                                        chips
                                        :rules=[validations.required]
                                        clearable
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-combobox>
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="hoResponsabilityRequest.concreteSlabRemoval.note"
                                        rows="2"
                                        row-height="15"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'A/C Relocation'" 
                                    :subDescription="'If access is too restricted, the A/C unit may need to be moved and the concrete pad may require repairs. Is this required?'"
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.acRelocation.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>                            
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.acRelocation.apply == 1"
                            >
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.acRelocation.yesNo" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                                
                                <div style="width: 100%;" v-if="hoResponsabilityRequest.acRelocation.yesNo == 1">
                                    <v-combobox
                                        v-model="hoResponsabilityRequest.acRelocation.listSelected"
                                        :items="listResponsible2"
                                        item-text="responsibleDescription"
                                        item-value="id"
                                        multiple
                                        chips
                                        :rules=[validations.required]
                                        clearable
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-combobox>
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="hoResponsabilityRequest.acRelocation.note"
                                        rows="2"
                                        row-height="15"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Downspout Relocation'" 
                                    :subDescription="'If relocation is necessary for excavation, removal and reinstallation will be the homeowner’s responsibility. If not moved, the homeowner will be responsible for any repair costs due to damage'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.downspoutRelocation.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.downspoutRelocation.apply == 1"
                            >
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.downspoutRelocation.yesNo" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>

                                <div style="width: 100%;" v-if="hoResponsabilityRequest.downspoutRelocation.yesNo == 1">
                                    <v-combobox
                                        v-model="hoResponsabilityRequest.downspoutRelocation.listSelected"
                                        :items="listResponsible2"
                                        item-text="responsibleDescription"
                                        item-value="id"
                                        multiple
                                        chips
                                        :rules=[validations.required]
                                        clearable
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-combobox>
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="hoResponsabilityRequest.downspoutRelocation.note"
                                        rows="2"
                                        row-height="15"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Permit Renewal'" 
                                    :subDescription="'If the project is halted due to homeowner requests, payment delays, inspections by other trades, failed inspections, revisions, or addendums, and the permit expires, there will be costs to renew the permit with the city'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.permitRenewal.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.permitRenewal.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.permitRenewal.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.permitRenewal.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Pool Barrier'" 
                                    :subDescription="'Various barriers are available, such as a perimeter fence, window and door alarms, screen enclosures, coping alarms, or baby fences. A separate permit may be required for some barriers. Changes to the pool barrier after permit submission will incur extra charges for resubmission'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.poolBarrier.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.poolBarrier.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.poolBarrier.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.poolBarrier.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'New Landscaping and Irrigation'" 
                                    :subDescription="'After the final grading, new landscaping can be scheduled. Irrigation and landscaping should be done, except for turf, which is recommended to be installed after plaster to avoid damage'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.newLandscapingIrrigation.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.newLandscapingIrrigation.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.newLandscapingIrrigation.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.newLandscapingIrrigation.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Gas'"
                                    :subDescription="'The subcontractor must apply for a permit, handle preparation, scheduling, inspections, and address any failed inspections. Underground piping must be installed after our plumbing work is completed'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.gas.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.gas.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.gas.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.gas.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Electrical'" 
                                    :subDescription="'The subcontractor must apply for a permit, handle preparation, scheduling, inspections, and address any failed inspections. Underground wiring must be installed after plumbing is completed. A bond wire will be required around the pool after patio grading. We can handle this for an additional cost if requested. When Van Kirk is responsible for electrical work, any holes required for running electrical wires and subsequent patchwork will be the homeowner’s responsibility'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.electrical.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.electrical.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.electrical.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.electrical.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Deck'" 
                                    :subDescription="'If the homeowner is installing their own deck, the subcontractor must apply for a permit, handle preparation, scheduling, inspections, disposal of material packaging, debris, and paver sand. After plumbing inspections pass, we will cover the pipes, but the homeowner must grade the yard and prepare it for deck installation. We are not responsible for deck elevation issues. If umbrella sleeves, deck jets, cannons, skimmer colors or lids are purchased, installation will be the homeowner’s responsibility'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.deck.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.deck.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.deck.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.deck.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Coping'" 
                                    :subDescription="'The subcontractor must prepare, schedule, and dispose of material packaging and debris. If the homeowner is installing their own coping, we are not responsible for grout lines or alterations to the beam, which could affect the shell warranty'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.coping.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.coping.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.coping.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.coping.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Tile'" 
                                    :subDescription="'If the homeowner is installing their own tile, we are not responsible for mud work, tile installation, leaks, waterproofing, fitting cuts, or finishes'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.tile.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.tile.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.tile.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.tile.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Backflow Preventer'" 
                                    :subDescription="'The subcontractor must apply for a permit for installation, which must also be certified by a licensed plumber'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.backflowPreventer.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.backflowPreventer.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.backflowPreventer.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.backflowPreventer.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Special Permit'" 
                                    :subDescription="'Your city or HOA may require special permits such as right-of-way, parking, or dewatering permits. We can obtain these permits, but additional costs may apply'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.specialPermit.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.specialPermit.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.specialPermit.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.specialPermit.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Form Board Survey'" 
                                    :subDescription="'Your city may require a form board survey after pool excavation and completion of form and steel work. If required, you will need to provide us with the survey. You may use the same company that conducted your original survey to expedite the process'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.formBoardSurvey.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.formBoardSurvey.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.formBoardSurvey.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.formBoardSurvey.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Final Survey'" 
                                    :subDescription="'A final survey may be required by your city or HOA once the project is completed. We will advise you when it’s time to request this survey'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.finalSurvey.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.finalSurvey.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.finalSurvey.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.finalSurvey.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Density Test'" 
                                    :subDescription="'Some cities may request a density test. Once provided, we will submit it to the city'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.densityTest.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.densityTest.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.densityTest.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.densityTest.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Powerlines'" 
                                    :subDescription="'If powerlines cross the future pool area, they must be moved or buried'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.powerlines.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.powerlines.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.powerlines.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.powerlines.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Well'" 
                                    :subDescription="'The city may require a well for dewatering'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.well.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.well.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.well.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.well.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Pipes and Wires'" 
                                    :subDescription="'Any underground pipes or wires that obstruct excavation must be removed or relocated. If damaged, repairs will be the homeowner’s responsibility. We will call locates before excavation to mark these items'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.pipesWires.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.pipesWires.apply == 1"
                            >
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.pipesWires.yesNo" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>

                                <div style="width: 100%;" v-if="hoResponsabilityRequest.pipesWires.yesNo == 1">
                                    <v-combobox
                                        v-model="hoResponsabilityRequest.pipesWires.listSelected"
                                        :items="listResponsible2"
                                        item-text="responsibleDescription"
                                        item-value="id"
                                        multiple
                                        chips
                                        :rules=[validations.required]
                                        clearable
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-combobox>
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="hoResponsabilityRequest.pipesWires.note"
                                        rows="2"
                                        row-height="15"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Underground Obstacles'" 
                                    :subDescription="'We cannot determine what is underground until excavation begins. Removal of boulders, hard rock, roots, or muck will incur additional costs'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.undergroundObstacles.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>                            
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.undergroundObstacles.apply == 1"
                            >
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.undergroundObstacles.yesNo" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Yes"
                                        :value="1"
                                        color="var(--color__main)"
                                    ></v-radio>
                                    <v-radio
                                        label="No"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>

                                <div style="width: 100%" v-if="hoResponsabilityRequest.undergroundObstacles.yesNo == 1">
                                    <v-combobox
                                        v-model="hoResponsabilityRequest.undergroundObstacles.listSelected"
                                        :items="listResponsible2"
                                        item-text="responsibleDescription"
                                        item-value="id"
                                        multiple
                                        chips
                                        :rules=[validations.required]
                                        clearable
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-combobox>
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="hoResponsabilityRequest.undergroundObstacles.note"
                                        rows="2"
                                        row-height="15"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                class="displayFlexEnd"
                            >
                                <ContentHeader 
                                    :styleHeader="'padding-top: 10px; padding-right: 10px; width: 80%;'" 
                                    :description="'Workspace'" 
                                    :subDescription="'Once excavation starts, the backyard and access areas will become a construction site with limited access. Ensure that gate entry, water, power, and parking spaces are available for the crew to work efficiently'" 
                                />
                                <v-radio-group 
                                    class="zeroMargin"
                                    v-model="hoResponsabilityRequest.workspace.apply" 
                                    row
                                    :rules=[validations.required]
                                >
                                    <v-radio
                                        label="Applicable"
                                        :value="1"
                                    ></v-radio>
                                    <v-radio
                                        label="Not Applicable"
                                        :value="2"
                                        color="var(--color__main)"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="hoResponsabilityRequest.workspace.apply == 1"
                            >
                                <v-combobox
                                    v-model="hoResponsabilityRequest.workspace.listSelected"
                                    :items="listResponsible"
                                    item-text="responsibleDescription"
                                    item-value="id"
                                    multiple
                                    chips
                                    :rules=[validations.required]
                                    clearable
                                    single-line
                                    outlined
                                    dense
                                >
                                </v-combobox>
                                <label>Notes</label>
                                <v-textarea
                                    v-model="hoResponsabilityRequest.workspace.note"
                                    rows="2"
                                    row-height="15"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions style="padding: 15px !important;">
                        <div style="width: 100%; text-align: center;">
                            <v-btn
                                class="mx-2 dialogButtonCancel v-btn-large"
                                outlined
                                @click="cancel"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                                class="mx-2 v-btn-large v-btn-large"
                                outlined
                                @click="confirm"
                            >
                                Confirm
                            </v-btn>
                        </div>
                    </v-card-actions>

                </v-card>
            </v-form>
        </v-dialog>
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import ContentHeader from "@/components/Layout/ContentHeader.vue";

	export default {

        mixins: [Helpers],

        components: {
            ContentHeader
        },

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            message: {
                type: String,
                default: ''
            },

			showDialogHOResp: {
				default: false
			},
		},
		
        data: () => ({

            validForm: true,
			
            validations: {
                required: required
            },

            hoResponsabilityRequest: {

                id: 0,
                idService: 0,

                landscaping: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                hoaApproval: {
                    apply: null,
                    yesNo: null,
                    listSelected: null,
                    note: null
                },

                cappingIrrigation: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                plywood: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                fenceRemoval: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                furnitureRemoval: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                paverRemoval: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                concreteSlabRemoval: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                acRelocation: {
                    apply: null,
                    yesNo: null,
                    listSelected: null,
                    note: null
                },

                downspoutRelocation: {
                    apply: null,
                    yesNo: null,
                    listSelected: null,
                    note: null
                },

                permitRenewal: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                poolBarrier: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                newLandscapingIrrigation: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                gas: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                electrical: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                deck: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                coping: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                tile: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                backflowPreventer: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                specialPermit: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                formBoardSurvey: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                finalSurvey: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                densityTest: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                powerlines: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                well: {
                    apply: null,
                    listSelected: null,
                    note: null
                },

                pipesWires: {
                    apply: null,
                    yesNo: null,
                    listSelected: null,
                    note: null
                },

                undergroundObstacles: {
                    apply: null,
                    yesNo: null,
                    listSelected: null,
                    note: null
                },

                workspace: {
                    apply: null,
                    listSelected: null,
                    note: null
                },
            },

            listResponsible: [],
            listResponsible2: []
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        watch: {

            async "id"() {
                await this.getHOResponsability();
            },
        },

        methods: {

            cancel() {
                this.$emit('update:showDialogHOResp', false);
            },

            async clearHOResponsability() {

                this.hoResponsabilityRequest = {
                    id: 0,
                    idService: 0,
                    landscaping: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    hoaApproval: {
                        apply: null,
                        yesNo: null,
                        listSelected: null,
                        note: null
                    },

                    cappingIrrigation: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    plywood: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    fenceRemoval: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    furnitureRemoval: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    paverRemoval: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    concreteSlabRemoval: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    acRelocation: {
                        apply: null,
                        yesNo: null,
                        listSelected: null,
                        note: null
                    },

                    downspoutRelocation: {
                        apply: null,
                        yesNo: null,
                        listSelected: null,
                        note: null
                    },

                    permitRenewal: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    poolBarrier: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    newLandscapingIrrigation: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    gas: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    electrical: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    deck: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    coping: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    tile: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    backflowPreventer: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    specialPermit: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    formBoardSurvey: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    finalSurvey: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    densityTest: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    powerlines: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    well: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },

                    pipesWires: {
                        apply: null,
                        yesNo: null,
                        listSelected: null,
                        note: null
                    },

                    undergroundObstacles: {
                        apply: null,
                        yesNo: null,
                        listSelected: null,
                        note: null
                    },

                    workspace: {
                        apply: null,
                        listSelected: null,
                        note: null
                    },
                };
            },

            async getHOResponsability() {
                
                await this.clearHOResponsability();

                const responseHomeownerResponsability = await this.$store.dispatch("serviceModule/GetColorHomeownerResponsabilityById", this.id );

                if (responseHomeownerResponsability.success) {
                    const result = responseHomeownerResponsability.result;

                    if (result != null && result != undefined && result.id != 0) {

                        this.hoResponsabilityRequest = {
                            id: result.id,
                            idService: result.idService,
                            landscaping: JSON.parse(result.landscaping),
                            hoaApproval: JSON.parse(result.hoaApproval),
                            cappingIrrigation: JSON.parse(result.cappingIrrigation),
                            plywood: JSON.parse(result.plywood),
                            fenceRemoval: JSON.parse(result.fenceRemoval),
                            furnitureRemoval: JSON.parse(result.furnitureRemoval),
                            paverRemoval: JSON.parse(result.paverRemoval),
                            concreteSlabRemoval: JSON.parse(result.concreteSlabRemoval),
                            acRelocation: JSON.parse(result.acRelocation),
                            downspoutRelocation: JSON.parse(result.downspoutRelocation),
                            permitRenewal: JSON.parse(result.permitRenewal),
                            poolBarrier: JSON.parse(result.poolBarrier),
                            newLandscapingIrrigation: JSON.parse(result.newLandscapingIrrigation),
                            gas: JSON.parse(result.gas),
                            electrical: JSON.parse(result.electrical),
                            deck: JSON.parse(result.deck),
                            coping: JSON.parse(result.coping),
                            tile: JSON.parse(result.tile),
                            backflowPreventer: JSON.parse(result.backflowPreventer),
                            specialPermit: JSON.parse(result.specialPermit),
                            formBoardSurvey: JSON.parse(result.formBoardSurvey),
                            finalSurvey: JSON.parse(result.finalSurvey),
                            densityTest: JSON.parse(result.densityTest),
                            powerlines: JSON.parse(result.powerlines),
                            well: JSON.parse(result.well),
                            pipesWires: JSON.parse(result.pipesWires),
                            undergroundObstacles: JSON.parse(result.undergroundObstacles),
                            workspace: JSON.parse(result.workspace),
                        };
                    }
                }
            },

            async getLists() {
                const listResponsibles = await this.$store.dispatch("serviceModule/ListResponsibles");

                this.listResponsible = [...listResponsibles];
                this.listResponsible2 = [...listResponsibles.filter(resp => resp.idResponsible != 1)];
            },

            async isValidateField(field) {
                if (field && 
                    field.apply == 1 && 
                    field.listSelected && 
                    field.listSelected.length > 1) {
                    return field.note != null &&
                           field.note != undefined &&
                           field.note.toString().trim() != '';
                }

                return true;
            },
            
            async validateForm() {

                let msgValidation = '';

                msgValidation =  await this.isValidateField(this.hoResponsabilityRequest.landscaping) == false ? '- Landscaping Removal <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.hoaApproval) == false ? '- HOA approval <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.cappingIrrigation) == false ? '- Capping irrigation <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.plywood) == false ? '- Plywood <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.fenceRemoval) == false ? '- Fence Removal <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.furnitureRemoval) == false ? '- Furniture Removal <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.paverRemoval) == false ? '- Paver Removal <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.concreteSlabRemoval) == false ? '- Concrete Slab Removal <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.acRelocation) == false ? '- A/C Relocation <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.downspoutRelocation) == false ? '- Downspout Relocation <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.permitRenewal) == false ? '- Permit Renewal <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.poolBarrier) == false ? '- Pool Barrier <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.newLandscapingIrrigation) == false ? '- New Landscaping and Irrigation <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.gas) == false ? '- Gas <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.electrical) == false ? '- Electrical <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.deck) == false ? '- Deck <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.coping) == false ? '- Coping <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.tile) == false ? '- Tile <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.backflowPreventer) == false ? '- Backflow Preventer <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.specialPermit) == false ? '- Special Permit <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.formBoardSurvey) == false ? '- Form Board Survey <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.finalSurvey) == false ? '- Final Survey <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.densityTest) == false ? '- Density Test <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.powerlines) == false ? '- Powerlines <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.well) == false ? '- Well  <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.pipesWires) == false ? '- Pipes and Wires <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.undergroundObstacles) == false ? '- Underground Obstacles <br />' : '';
                msgValidation += await this.isValidateField(this.hoResponsabilityRequest.workspace) == false ? '- Workspace <br />' : '';


                if (msgValidation != '') {
                    this.showToast("error", "Warning!", `The sections below require notes to be completed: <br /><br /> ${msgValidation}`);
                }

                return msgValidation == '';
            },

            async confirm() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the contact form. Please review!");
                    return false;
                }
                else if (await this.validateForm() ) {

                    const newHORespRequest = {
                        id: this.hoResponsabilityRequest.id,
                        idService: this.id,
                        landscaping: JSON.stringify(this.hoResponsabilityRequest.landscaping),
                        hoaApproval: JSON.stringify(this.hoResponsabilityRequest.hoaApproval),
                        cappingIrrigation: JSON.stringify(this.hoResponsabilityRequest.cappingIrrigation),
                        plywood: JSON.stringify(this.hoResponsabilityRequest.plywood),
                        fenceRemoval: JSON.stringify(this.hoResponsabilityRequest.fenceRemoval),
                        furnitureRemoval: JSON.stringify(this.hoResponsabilityRequest.furnitureRemoval),
                        paverRemoval: JSON.stringify(this.hoResponsabilityRequest.paverRemoval), 
                        concreteSlabRemoval: JSON.stringify(this.hoResponsabilityRequest.concreteSlabRemoval),
                        acRelocation: JSON.stringify(this.hoResponsabilityRequest.acRelocation),
                        downspoutRelocation: JSON.stringify(this.hoResponsabilityRequest.downspoutRelocation),
                        permitRenewal: JSON.stringify(this.hoResponsabilityRequest.permitRenewal),
                        poolBarrier: JSON.stringify(this.hoResponsabilityRequest.poolBarrier),
                        newLandscapingIrrigation: JSON.stringify(this.hoResponsabilityRequest.newLandscapingIrrigation),
                        gas: JSON.stringify(this.hoResponsabilityRequest.gas),
                        electrical: JSON.stringify(this.hoResponsabilityRequest.electrical),
                        deck: JSON.stringify(this.hoResponsabilityRequest.deck),
                        coping: JSON.stringify(this.hoResponsabilityRequest.coping),
                        tile: JSON.stringify(this.hoResponsabilityRequest.tile),
                        backflowPreventer: JSON.stringify(this.hoResponsabilityRequest.backflowPreventer),
                        specialPermit: JSON.stringify(this.hoResponsabilityRequest.specialPermit),
                        formBoardSurvey: JSON.stringify(this.hoResponsabilityRequest.formBoardSurvey),
                        finalSurvey: JSON.stringify(this.hoResponsabilityRequest.finalSurvey),
                        densityTest: JSON.stringify(this.hoResponsabilityRequest.densityTest),
                        powerlines: JSON.stringify(this.hoResponsabilityRequest.powerlines),
                        well: JSON.stringify(this.hoResponsabilityRequest.well), 
                        pipesWires: JSON.stringify(this.hoResponsabilityRequest.pipesWires),
                        undergroundObstacles: JSON.stringify(this.hoResponsabilityRequest.undergroundObstacles),
                        workspace: JSON.stringify(this.hoResponsabilityRequest.workspace),
                    }

                    const result = await this.$store.dispatch("serviceModule/CreateUpdateHomeownerResponsability", newHORespRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.$emit('methodConfirmToCall');
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }
                }
            }
        },

        async created() {
            await this.getLists();
        }
    };
</script>

<style scoped>
    .displayFlexEnd {
        display: flex;
        align-items: flex-end;
    }

    .displayFlex {
        display: flex;
        align-items: center;
        width: 90% !important;
    }

    .zeroMargin {
        margin: 0px !important;
    }
</style>